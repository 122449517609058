import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../context/auth/AuthContext";
import AlertContext from "../context/alert/AlertContext";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const Register = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const { isRegisted, register, changeStateRegister } = authContext;
  const { setAlert } = alertContext;

  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    departement: "gni",
  });

  const [errors, setErrors] = useState({});
  const { first_name, last_name, email, password, departement } = newUser;

  const onChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };
  // redirection de l'utilisateur apres ajout
  /* useEffect(() => {
    if (isRegisted) {
      props.history.push("/login");
      changeStateRegister();
    }
  }, [isRegisted, props.history]); */

   useEffect(() => {
    
  }, [isRegisted]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      first_name === "" ||
      last_name === "" ||
      email === "" ||
      password === ""
    ) {
      //setAlert("Completer les champs libres", "danger");
      NotificationManager.error(
        "Completer les champs libres dans le formulaire !",
        " Message d'erreur",
        5000
      );
    } else {
     // setAlert("Enregistrement effectue", "success");
     
      register(newUser);

      setNewUser({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        departement: "gni",
      });
      NotificationManager.success(
        "Enregistrement effectué !",
        "Message",
        5000
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="mx-2">
          <form noValidate onSubmit={onSubmit}>
            {/* <h1 className="h3 mb-3 font-weight-normal">Création de compte</h1> */}
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                className="form-control form-control-sm border border-secondary"
                name="first_name"
                placeholder="Entrer votre nom"
                value={first_name}
                required
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Prénom</label>
              <input
                type="text"
                className="form-control form-control-sm border border-secondary"
                name="last_name"
                placeholder="Entrer votre prénom"
                value={last_name}
                required
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Adresse mail</label>
              <input
                type="email"
                className="form-control form-control-sm border border-secondary"
                name="email"
                placeholder="Entrer adresse email"
                value={email}
                required
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Mot de passe</label>
              <input
                type="password"
                className="form-control form-control-sm border border-secondary"
                name="password"
                placeholder="Entrer votre mot de passe"
                value={password}
                required
                onChange={onChange}
              />
            </div>
            {/* <div className="form-group">
                <label htmlFor="departement">Département</label>
                <select class="form-control form-control-sm border border-secondary" id="departement"   name="departement" required value={departement} onChange={onChange}>
                    <option value="compta">Compta</option>
                    <option value="Tresor">Trésor</option>
                </select>
              </div> */}
            <button type="submit" className="btn btn-sm btn-danger">
              Ajouter
            </button>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Register;
