import React, {
  Fragment,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { Button, Card } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import ShowTable from "../recettes/ShowTable";
import RecetteContext from "../context/RecetteContext";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Register from "./Register";

const Parametrage = (props) => {
  const recetteContext = useContext(RecetteContext);
  const [startDate, setStartDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const { recettePartenaires } = props;
  const [greffeTemp, setGreffeTemp] = useState(0);
  const [ogapiTemp, setOgapiTemp] = useState(0);
  const [journalTemp, setJournalTemp] = useState(0);
  const [clikAfricTemp, setClikAfricTemp] = useState(0);
  const [totalTemp, setTotalTemp] = useState(0);
  const [totalFinalD, setTotalFinalD] = useState(0);
  const handleDate = (date) => {
    setStartDate(date);
  };
  const { addRecettePartenaires, getRecettePartenaires } = recetteContext;
  const [partenaire, setPartenaire] = useState({
    dateComptable: startDate,
    total: "",
    clikAfric: "",
    greffe: "",
    ogapi: "",
    journalOfficiel: "",
    totalFinal: "",
  });

  let {
    dateComptable,
    total,
    clikAfric,
    greffe,
    ogapi,
    journalOfficiel,
    totalFinal,
  } = partenaire;

  // Ref
  let totalDGIRef = useRef(null);
  let greffeRef = useRef(null);
  let ogapiRef = useRef(null);
  let journalOfficielRef = useRef(null);
  let clikAfricRef = useRef(null);

  useEffect(() => {
    //getRecettePartenaires();
  }, [recettePartenaires]);

  const handleValue = (e) => {
    setPartenaire({ ...partenaire, [e.target.name]: e.target.value });
  };

  const handleClikAfricTemp = (e) => {
    setClikAfricTemp(parseInt(e));
  };

  const handleGreffeTemp = (e) => {
    setGreffeTemp(parseInt(e));
  };

  const handleOgapiTemp = (e) => {
    setOgapiTemp(parseInt(e));
  };

  const handleJournalTemp = (e) => {
    setJournalTemp(parseInt(e));
  };

  const handleTotalTemp = (e) => {
    setTotalTemp(parseInt(e));
  };

  useEffect(() => {
    handleTotalLigne();
  }, [totalTemp, greffeTemp, ogapiTemp, journalTemp, clikAfricTemp]);

  const handleTotalLigne = () => {
    if (isNaN(totalTemp)) {
      setTotalTemp(0);
    }
    if (isNaN(greffeTemp)) {
      setGreffeTemp(0);
    }
    if (isNaN(ogapiTemp)) {
      setOgapiTemp(0);
    }
    if (isNaN(journalTemp)) {
      setJournalTemp(0);
    }
    if (isNaN(clikAfricTemp)) {
      setClikAfricTemp(0);
    }

    setTotalFinalD(
      totalTemp + greffeTemp + ogapiTemp + journalTemp + clikAfricTemp
    );
  };
  const handleAddRecettePartenaire = (e) => {
    e.preventDefault();
    console.log(partenaire)
    if (recettePartenaires.length === 0 && totalFinalD > 0) {
      partenaire.dateComptable = startDate;
      partenaire.totalFinal = totalFinalD;
      partenaire.clikAfric = clikAfricTemp;
      partenaire.journalOfficiel = journalTemp;
      partenaire.ogapi = ogapiTemp;
      partenaire.greffe = greffeTemp;
      partenaire.total = totalTemp;
      console.log(partenaire);
      addRecettePartenaires(partenaire);
      console.log("enregistrement");

      setPartenaire({
        dateComptable: startDate,

        total: "",
        clikAfric: "",
        greffe: "",
        ogapi: "",
        journalOfficiel: "",
        totalFinal: "",
      });
      setTotalFinalD(0);
      NotificationManager.success(
        "La valeur initial des partenaires enregistré   !",
        "Message",
        5000
      );
    } else {
      NotificationManager.error(
        "La valeur initial existant ou valeurs erronés !",
        " Message d'erreur",
        5000
      );
    }
  };

  const ajoutPartenaireInit = () => (
    <Card className="mt-3">
      <Card.Header as="h5">Base initial des recettes</Card.Header>
      <Card.Body>
        <Card.Title></Card.Title>
        <Card.Text>
          <form onSubmit={handleAddRecettePartenaire}>
            <Row>
              <Col>
                <div className="form-group  col-md-9">
                  <label htmlFor="totalDGI">Date du report</label>
                  <br />
                  <DatePicker
                    className="form-control  form-control-sm"
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={handleDate}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group  col-md-9">
                  <label htmlFor="totalDGI">Total DGI</label>
                  <NumberFormat
                    className="form-control form-control-sm"
                    name="total"
                    placeholder="Total"
                    value={total}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleTotalTemp(value);
                    }}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        greffeRef.focus();
                      }
                    }}
                    ref={clikAfricRef}
                    getInputRef={(el) => (clikAfricRef = el)}
                    onChange={handleValue}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group col-md-9">
                  <label htmlFor="">Frais CLIK AFRIC</label>

                  <NumberFormat
                    className="form-control form-control-sm"
                    placeholder="Frais CLIK AFRIC"
                    name="clikAfric"
                    value={clikAfric}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleClikAfricTemp(value);
                    }}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        greffeRef.focus();
                      }
                    }}
                    ref={clikAfricRef}
                    getInputRef={(el) => (clikAfricRef = el)}
                    onChange={handleValue}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="form-group  col-md-9">
                  <label htmlFor="FraisGreffe">Frais GREFFE</label>

                  <NumberFormat
                    className="form-control form-control-sm"
                    placeholder="Frais de Greffe"
                    name="greffe"
                    value={greffe}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleGreffeTemp(value);
                    }}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        ogapiRef.focus();
                      }
                    }}
                    ref={greffeRef}
                    getInputRef={(el) => (greffeRef = el)}
                    onChange={handleValue}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group  col-md-9">
                  <label htmlFor="FraisOgapi">Frais OGAPI</label>

                  <NumberFormat
                    className="form-control form-control-sm"
                    placeholder="Frais Ogapi"
                    name="ogapi"
                    value={ogapi}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleOgapiTemp(value);
                    }}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        journalOfficielRef.focus();
                      }
                    }}
                    ref={ogapiRef}
                    getInputRef={(el) => (ogapiRef = el)}
                    onChange={handleValue}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group  col-md-9">
                  <label htmlFor="journalOfficielOfficiel">
                    Frais Journal Officiel
                  </label>
                  <NumberFormat
                    className="form-control form-control-sm"
                    placeholder="Insertion journal officiel"
                    name="journalOfficiel"
                    value={journalOfficiel}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleJournalTemp(value);
                    }}
                    ref={journalOfficielRef}
                    getInputRef={(el) => (journalOfficielRef = el)}
                    onChange={handleValue}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-row col-md-10">
                  <div className="form-group form-control-sm">
                    <label htmlFor="totalF">Total</label>
                    <NumberFormat
                      className="form-control form-control-sm"
                      disabled
                      placeholder="Total"
                      value={totalFinalD}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </div>
                </div>
              </Col>
              <Col></Col>
              <Col>
                <button type="submit" className="mt-4 btn btn-primary">
                  Enregistrer
                </button>
              </Col>
            </Row>
          </form>
        </Card.Text>
      </Card.Body>
    </Card>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("genere");
  };

  return (
    <div style={{ margin: "20px" }}>
      {recettePartenaires.length === 0 ? ajoutPartenaireInit() : null}
      <Card className="mt-3">
        <Card.Header as="h5">Liste x </Card.Header>
        <Card.Body>
          <Card.Text>
            <ShowTable
              choix={"tout-sans-anpi-partenaire"}
              filterRecettes={recettePartenaires}
              listMontant={[]}
            />
          </Card.Text>
        </Card.Body>
      </Card>
      <div className="row">
        <div className="col-md-4">
          <Card className="mt-3">
            <Card.Header as="h5">Créer un utilisateur</Card.Header>
            <Card.Body>
              <Card.Text>
                <Register />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-8">
          <Card className="mt-3">
            <Card.Header as="h5">Liste des utilisateurs</Card.Header>
            <Card.Body>
              <Card.Text>
                <div className="row">
                  <div className="col-md-3"></div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
};

export default Parametrage;
