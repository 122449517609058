import React, { useEffect, useContext, useState } from 'react';
import RecetteContext from "../context/RecetteContext"
import RestantPartenairesItems from "./RestantPartenairesItems"
import { Table } from "react-bootstrap";
import { headerFun } from "../utility/Utils";
import moment from "moment";

const RestantPartenaires = ({dateCompt, restantPartenaires}) => {
   
    const recetteContext = useContext(RecetteContext);
    const { } = recetteContext
    const [filterRestantPartenaires, setFilterRestantPartenaires] = useState([])
  /* useEffect(() => {
    allRestantPartenaires()
    console.log("restant Partenaires : ")
    console.log(restantPartenaires)
  }, []) */

const filterMonth = () => {
  return restantPartenaires.filter(elt => moment(elt.dateComptable).isSame(dateCompt, 'month'))
}

  useEffect(() => {
   // allRestantPartenairesByMonth(dateCompt);
   setFilterRestantPartenaires(filterMonth())
   console.log("restant Partenaire filtrer")
   console.log(filterRestantPartenaires)
  }, [dateCompt, restantPartenaires])

    return (
        <div className="table-wrapper-scroll-y my-custom-scrollbar">
           <Table responsive id="table-to-xls" size="sm" variant="light" striped bordered hover >    
                <thead className="thead-light">
                  <tr>
                  <th scope="col" >#</th>
                    {headerFun("versement").map(e => ( <th scope="col" style={{ whiteSpace: "nowrap" }}>{e}</th>))}
                  </tr>
                </thead>
                <tbody>
                     { filterRestantPartenaires.map((restant, i) => (
                      <RestantPartenairesItems restant={restant} numLigne={i+1} key={i} />
                    ))
                    } 
                </tbody>
            </Table>
        </div>
    );
};

export default RestantPartenaires;