import React, { useState, useEffect, useContext, useRef } from "react";
import { recetteMoisOf } from "../utility/Utils";
import { Row, Col } from "react-bootstrap";
import ShowTable from "./ShowTable";
import { v4 as uuidv4 } from "uuid";
import RecetteContext from "../context/RecetteContext";
import RestantPartenaires from "./RestantPartenaires";
import JsfPdfDoc from "../Etats/JsPdfDoc";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import NumberFormat from "react-number-format";
import OptionsTable from "./optionsTable";
import moment from "moment";

const PartnersMois = ({
  dateCompt,
  listMontant,
  bilanPartnaire,
  restantPartenaires,
}) => {
  const recetteContext = useContext(RecetteContext);
  const { addRestantPartenaireGni } = recetteContext;
  const [versement, setVersement] = useState(0);
  const [versementJo, setVersementJo] = useState("");
  const [versementOgapi, setVersementOgapi] = useState("");
  const [versementGreffe, setVersementGreffe] = useState("");
  const [versementClikAfric, setVersementClikAfric] = useState("");
  const [quote, setQuote] = useState("");
  const [restant, setRestant] = useState(0);
  const [restantPartenaire, setRestantPartenaire] = useState({
    dateComptable: "",
    versementJo: "",
    versementOgapi: "",
    versementGreffe: "",
    versementClikAfric: "",
    quoteR: "",
    totalVersement: "",
    restantR: "",
  });

  const versementJoRef = useRef(null);
  const versementOgapiRef = useRef(null);
  const versementGreffeRef = useRef(null);
  const quoteRef = useRef(null);
  const versementBtn = useRef(null);
  const versementClickAfricRef = useRef(null);
  let [montantPartenairesMois, setMontantPartenairesMois] = useState(0);

  useEffect(() => {
    handleReste();
  }, [versement, quote, montantPartenairesMois]);

  useEffect(() => {
    handleSommeVersement();
  }, [versementJo, versementOgapi, versementGreffe, versementClikAfric]);

  useEffect(() => {
    setMontantPartenairesMois(bilanPartnaire[0].totalPartenaire);
    console.log("Partenaire mois ---");
    console.log(restantPartenaires);
  }, [bilanPartnaire]);

  const handleSubmit = (e) => {
    e.preventDefault();

    restantPartenaire.dateComptable = dateCompt;
    restantPartenaire.versementJo = versementJo;
    restantPartenaire.versementOgapi = versementOgapi;
    restantPartenaire.versementGreffe = versementGreffe;
    restantPartenaire.versementClikAfric = versementClikAfric;
    restantPartenaire.quoteR = quote;
    restantPartenaire.totalVersement = versement;
    restantPartenaire.restantR = restant;

    //Add Line
    if (
      restantPartenaire.totalVersement > 0 &&
      restantPartenaire.restantR >= 0
    ) {
      console.log(restantPartenaire);
      addRestantPartenaireGni(restantPartenaire);

      setRestantPartenaire({
        id: "",
        dateComptable: "",
        versementJo: "",
        versementOgapi: "",
        versementGreffe: "",
        versementClikAfric: "",
        quoteR: "",
        totalVersement: "",
        restantR: "",
      });

      setVersement(0);
      setVersementJo(0);
      setVersementOgapi(0);
      setVersementGreffe(0);
      setVersementClikAfric(0);
      setQuote(0);
    } else {
      console.log("pas assez de donnes")
    }

    handleVersementJoFocus();
  };

  const handleVersementJoFocus = () => {
    versementJoRef.current.focus();
  };

  const handleVersement = (e) => {
    setVersement(e.target.value);
  };

  const handleJo = (e) => {
    setVersementJo(e.target.value);
  };

  const handleOgapi = (e) => {
    setVersementOgapi(e.target.value);
  };

  const handleGreffe = (e) => {
    setVersementGreffe(e.target.value);
  };

  const handleClikAfric = (e) => {
    setVersementClikAfric(e.target.value);
  };

  const handleQuote = (e) => {
    setQuote(e.target.value);
  };

  const handleReste = () => {
    let reste,
      versementF,
      quoteF = 0;

    if (versement === "") {
      versementF = 0;
    } else {
      versementF = versement;
    }
    if (quote === "") {
      quoteF = 0;
    } else {
      quoteF = quote;
    }

    reste = montantPartenairesMois - (parseInt(versementF) + parseInt(quoteF));
    setRestant(reste);
  };

  const handleSommeVersement = () => {
    let somme,
      Jo,
      Ogapi,
      Greffe,
      clikAfric = 0;

    if (versementOgapi === "") {
      Ogapi = 0;
    } else {
      Ogapi = parseInt(versementOgapi);
    }
    if (versementJo === "") {
      Jo = 0;
    } else {
      Jo = versementJo;
    }

    if (versementGreffe === "") {
      Greffe = 0;
    } else {
      Greffe = versementGreffe;
    }

    if (versementClikAfric === "") {
      clikAfric = 0;
    } else {
      clikAfric = versementClikAfric;
    }

    somme =
      parseInt(Ogapi) + parseInt(Jo) + parseInt(Greffe) + parseInt(clikAfric);
    setVersement(somme);
  };

  return (
    <div style={{ border: "solid", padding: "10px", margin: "10px" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: "20px" }}>
          <Row>
            <Col  >
              <Row >
                <Col >
                  <label>Versements JO : </label>
                  <input
                    //type="number"
                    className="form-control form-control-sm"
                    ref={versementJoRef}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        versementOgapiRef.current.focus();
                      }
                    }}
                    name="jo"
                    onChange={handleJo}
                    onKeyUp={handleJo}
                    value={versementJo}
                    placeholder="0"
                    /* thousandSeparator={"."}
                  decimalSeparator={","} */
                  />
                </Col>
                <Col >
                  <label>Versements OGAPI : </label>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    ref={versementOgapiRef}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        versementGreffeRef.current.focus();
                      }
                    }}
                    name="ogapi"
                    onChange={handleOgapi}
                    onKeyUp={handleOgapi}
                    value={versementOgapi}
                    placeholder="0"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Versements GREFFE : </label>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    name="greffe"
                    onChange={handleGreffe}
                    onKeyUp={handleGreffe}
                    ref={versementGreffeRef}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        versementClickAfricRef.current.focus();
                      }
                    }}
                    value={versementGreffe}
                    placeholder="0"
                  />
                </Col>
                <Col >
                  <label>Versements CLIK AFRIC : </label>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    name="greffe"
                    onChange={handleClikAfric}
                    onKeyUp={handleClikAfric}
                    ref={versementClickAfricRef}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        e.preventDefault();
                        quoteRef.current.focus();
                      }
                    }}
                    value={versementClikAfric}
                    placeholder="0"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <label>Quote-part Anpi-Gabon</label>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    ref={quoteRef}
                    onKeyPress={(e) => {
                      const { key } = e;
                      if (key === "Enter") {
                        //e.preventDefault()
                        //versementBtn.current.focus()
                      }
                    }}
                    name="quote"
                    onChange={handleQuote}
                    onKeyUp={handleQuote}
                    value={quote}
                    placeholder="0"
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col md={{ span: 8, offset: 3 }}>
                  <label>Versements effectués aux partenaires</label>
                  <NumberFormat
                    //type="number"
                    className="form-control form-control-sm"
                    disabled="true"
                    name="versement"
                    /*  onChange={handleVersement}
                onKeyUp={handleVersement} */
                    value={versement}
                    //placeholder="0"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                  <br />
                  <label>Total partenaires</label>
                  {/* <input type="text" className="form-control" name="restant" placeholder={recetteMoisOf(montantPartenairesMois)} disabled/> */}
                  <NumberFormat
                    className="form-control form-control-sm"
                    name="restant"
                    value={montantPartenairesMois}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    disabled
                  />
                  <br />
                  <label>Restant dû aux partenaires</label>
                  {/* <input type="text" className="form-control" value={restant} /> */}
                  <NumberFormat
                    className="form-control form-control-sm"
                    name="restant"
                    value={restant}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    disabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row>
          <Col></Col>
        </Row>
        <button type="submit" ref={versementBtn} className="btn btn-primary btn-sm">
          Enregistrer
        </button>
      </form>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  pb-2 mb-3 border-bottom">
        <div></div>
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            <OptionsTable
              choix="versement"
              pdfTitle="SUIVI DES RECETTES DU GUICHET DE L'INVESTISSEMENT"
              datas={restantPartenaires.filter((elt) =>
                moment(elt.dateComptable).isSame(dateCompt, "month")
              )}
              
            />
          </div>
        </div>
      </div>
      <RestantPartenaires
        restantPartenaires={restantPartenaires}
        dateCompt={dateCompt}
      />
    </div>
  );
};

export default PartnersMois;
