import React, { useContext, useEffect, useState } from "react";
import SubRoutes from "../routing/SubRoutes";
import RecetteContext from "../context/RecetteContext";

const Overview = (props) => {
  let path = props.match.path;
  const recetteContext = useContext(RecetteContext);
  const {
    allRecettes,
    recettes,
    getRecettePartenaires,
    recettePartenaires,
    restantPartenaires,
    allRestantPartenaires
  } = recetteContext;

  const fetchData = async () => {
    await allRecettes();
    await getRecettePartenaires();
    await allRestantPartenaires();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <SubRoutes
        path={path}
        recettes={recettes}
        recettePartenaires={recettePartenaires}
        restantPartenaires={restantPartenaires}
      />
    </div>
  );
};

export default Overview;
