import React, { Fragment, useState } from "react";
import RecetteContext from "../context/RecetteContext";
import { useContext } from "react";
import RecetteUpdate from "./RecetteUpdate";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import formatMontant from "../utils/formatMontant";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const RecetteItems = ({ recette, numLigne }) => {
  const recetteContext = useContext(RecetteContext);
  const { deleteGni } = recetteContext;
  const [show, setShow] = useState(false);
  let [selectedRecette, setSelectedRecette] = useState({});

  const {
    id,
    dateComptable,
    quantite,
    ordre,
    formExpress,
    locationAutre,
    fraisAnpi,
    droitGni,
    fraisAnpiPart,
    fraisAnpiTotal,
    fraisClickAfric,
    droits,
    penalite,
    timbres,
    total,
    greffe,
    ogapi,
    insertionJournal,
    totalFinal,
  } = recette;

  const handleDelete = (id) => {
   // if (window.confirm(`Vous supprimer le NºQA ${quantite} ?`)) {
      console.log('delete id ', id)
    
      deleteGni(id);
   // }
  };

  const handleUpdate = () => {
    console.log(recette);
    //setSelectedRecette(recette)
    setSelectedRecette(recette);
    setShow(!show);
  };

  return (
    <Fragment>
      <tr>
        <th scope="row">{numLigne}</th>
        <td>
          <Moment format="DD/MM/YYYY">{dateComptable}</Moment>
        </td>
        <td>{quantite}</td>
        <td>{ordre}</td>
        <td>{formatMontant(formExpress)}</td>
        <td>{formatMontant(locationAutre)}</td>
        <td>{formatMontant(fraisAnpi)}</td>
        <td>{formatMontant(droitGni)}</td>
        <td>{formatMontant(fraisAnpiPart)}</td>
        <td>{formatMontant(fraisAnpiTotal)}</td>
        <td>{formatMontant(fraisClickAfric)}</td>
        <td>{formatMontant(droits)}</td>
        <td>{formatMontant(penalite)}</td>
        <td>{formatMontant(timbres)}</td>
        <td>{formatMontant(total)}</td>
        <td>{formatMontant(greffe)}</td>

        <td>{formatMontant(ogapi)}</td>
        <td>{formatMontant(insertionJournal)}</td>
        <td>{formatMontant(totalFinal)}</td>
        <td style={{ whiteSpace: "nowrap" }}>
          <button className="btn btn-sm btn-danger" onClick={() => handleDelete(id)}>
            <FontAwesomeIcon icon={faTrashAlt} /> Supprimer
          </button> {' '}
          <button className="btn btn-sm btn-primary" onClick={handleUpdate}>
            <FontAwesomeIcon icon={faEdit} /> Éditer
          </button>
        </td>
      </tr>
      <RecetteUpdate
        recette={selectedRecette}
        onShow={show}
        change={handleUpdate}
      />
      <NotificationContainer />
    </Fragment>
  );
};

export default RecetteItems;
