import React from "react";

import { Switch, Route, Redirect} from "react-router-dom"
import Login from "../pages/Login"
import Overview from "../pages/Overview";
import Register from "../pages/Register"

const MainRoutes = () => {
  return (
    <Switch>
      <Route  path="/dashboard" component={Overview}/>
    </Switch>
  )
    
  
};

export default MainRoutes;
