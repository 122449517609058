export default function(state, action) {
    switch (action.type) {
        case "USER_LOADED":
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: action.payload
            }
        case "CHANGE_REGISTER_STATE":
            return {
                ...state,
                isRegisted: false,
            }
        case "REGISTER_SUCCESS":
            return {
                ...state,
                ...action.payload,
                isRegisted: true,
                loading: false
            }
        case "LOGIN_SUCCESS":    
            localStorage.setItem('usertoken', action.payload)
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                loading: false
            }
        case "REGISTER_FAIL":
        case "AUTH_ERROR":
        case "LOGIN_FAIL":
        case "LOGOUT":
            localStorage.removeItem('usertoken')
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: action.payload
            }
        default:
            break;
    }
}