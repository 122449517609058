import React, { useReducer } from 'react';
import AlertContext from './AlertContext';
import AlertReducer from './AlertReducer';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import setAuthToken from '../../utils/setAuthToken';

const AlertState = (props) => {

    //const url = 'http://169.254.2.250:5000'
    const url = 'http://localhost:5000'
    //const url = ''
    const initialState = []

    const [state, dispatch] = useReducer(AlertReducer, initialState)

    // Set Alert
    const setAlert = (msg, type, timeout = 5000) => {
        const id = uuidv4();
        dispatch({
            type: "SET_ALERT",
            payload: { msg, type, id }
        })

        setTimeout(() => dispatch({
            type: "REMOVE_ALERT",
            payload: id
        }), timeout)
    }

    return (
        <AlertContext.Provider value={{
            alerts: state,
            setAlert
        }}>
            { props.children }
        </AlertContext.Provider>
    );
};

export default AlertState;