import React from "react";
import PrivateRoute from "../routing/PrivateRoute";
import { Switch, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import RecettesPage from "../pages/RecettesPage";
import RecetteMois from "../recettes/RecetteMois";
import Parametrage from "../pages/Parametrage";

const SubRoutes = (props) => {
  const { path,  recettes, recettePartenaires, restantPartenaires } = props;

  return (
    <Switch>
      
      <PrivateRoute
        exact
        path={`${path}/saisieRecette`}
        component={() => <HomePage recettes={recettes} />}
      />
     
      <Route
        path={`${path}/recettes`}
        render={(props) => <RecettesPage {...props} recettes={recettes} />}
      />
      
      <Route
        path={`${path}/recettesMois`}
        render={(props) => (
          <RecetteMois
            {...props}
            recettes={recettes}
            recettePartenaires={recettePartenaires}
            restantPartenaires={restantPartenaires}
          />
        )}
      />

      <Route
        path={`${path}/parametrage`}
        render={(props) => (
          <Parametrage
            {...props}
            recettes={recettes}
            recettePartenaires={recettePartenaires}
            restantPartenaires={restantPartenaires}
          />
        )}
      />
    </Switch>
  );
};

export default SubRoutes;
