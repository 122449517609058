import moment from "moment";

export const recetteMoisOf = (table) => {
  if (table != null) {
    let total = 0;
    for (let index = 0; index < table.length; index++) {
      total += table[index];
    }
    return total;
  } else {
    return 0;
  }
};

//Filtrer un tableau en fonction de la date debut et de la date fin
export const filtrerDate = (finances, debut, fin) => {
  return finances.filter((finance) => {
    if (
      /*   new Date(moment(finance.dateComptable).format("L"))  >= new Date(moment(debut).format("L")) &&
      new Date(moment(finance.dateComptable).format("L")) <= new Date(moment(fin).format("L"))
 */
      moment(moment(finance.dateComptable).format("L")).isBetween(
        moment(debut).format("L"),
        moment(fin).format("L"),
        undefined,
        "[]"
      )
    ) {
      return finance;
    }
  });
};

// Convert date in format DD-MM-YYYY
export const convertDate = (array) => {
  return array.map((data) => ({
    ...data,
    dateComptable: moment(data.dateComptable).format("L"),
  }));
};

//Group element by object property
export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    return {
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
    };
  }, {});

export const sortDate = (filterTable, ordre) => {
  let sortDate = [];
  switch (ordre) {
    case "dc":
      sortDate = filterTable
        .slice()
        .sort(
          (a, b) =>
            new Date(moment(b.dateComptable).format("L")) -
            new Date(moment(a.dateComptable).format("L"))
        );
      return sortDate;
      break;

    default:
      sortDate = filterTable
        .slice()
        .sort(
          (a, b) =>
            new Date(moment(a.dateComptable).format("L")) -
            new Date(moment(b.dateComptable).format("L"))
        );
      return sortDate;
      break;
  }
};

// Group element by date
export const groupedByDate = (recettes) => {
  //new Date(moment(b.dateComptable).format("L"))
  var trie = recettes.reduce(function (acc, obj) {
    var cle = moment(new Date(obj["dateComptable"])).format("L");
    //console.log(cle)
    if (!acc[cle]) {
      acc[cle] = [];
    }
    acc[cle].push(obj);
    return acc;
  }, {});
  console.log(trie);
};

export const headerFun = (choix) => {
  const header = {
    journal: ["#","Insertion Journal Officiel"],
    ogapi: ["#","Frais Ogapi"],
    greffe: ["#","Frais Greffe"],
    dgi: ["#","Droits", "Penalites", "Timbres", "Total DGI"],
    droits: ["#","Droits"],
    penalites: ["#","Penalites"],
    timbres: ["#","Timbres"],
    fraisClickAfric: ["#","CLIK AFRIC"],
    anpi: [
      "Formule Express",
      "Location et autre",
      "Frais ANPI",
      "Droits GNI",
      "Frais ANPI(60%)",
      "Frais ANPI total",
    ],
    allWithoutAnpi: [
      "Total DGI",
      "Frais Greffe",
      "Frais Ogapi",
      "Insertion Journal Officiel",
      "Total Partenaires",
    ],
    restantPartenaire: [
      "Versement",
      "Quote-part ANPI",
      "Total partenaires",
      "Restant partenaires",
    ],
    etat: ["Suivi individualisé Etat"],
    allFinance: [
      "DENOMINATION SOCIALE",
      "N°ORDRE",
      "FRAIS (FCFA)",
      "ANPI",
      "DGI",
      "GREFFE",
      "OGAPI",
      " J.O",
    ],
    versement: [
      "Date Comptable",
      "VersementJo",
      "VersementOgapi",
      "VersementGreffe",
      "VersementFrikAfric",
      "Total Versement",
      "Quote-part",
      "Restant"
    ],
    all: [
      "N° d'ordre",
      "Date",
      "N° QA",
      "N° OR",
      "Formule Express",
      "Location et autre",
      "Frais ANPI",
      "Droits GNI",
      "Frais ANPI(60%)",
      "Frais ANPI total",
      "Droits (Code 38)",
      "Pénalités (Code 81)",
      "Timbres (Code 75)",
      "Total DGI",
      "Frais CLIK AFRIC",
      "Frais Greffe",
      "Frais OGAPI",
      "Frais Journal officiel",
      "Total",
    ],
    allGni: [
      "N° d'ordre",
      "Date",
      "Formule Express",
      "Location et autre",
      "Frais ANPI",
      "Droits GNI",
      "Frais ANPI(60%)",
      "Frais ANPI total",
      "Droits (Code 38)",
      "Pénalités (Code 81)",
      "Timbres (Code 75)",
      "Total DGI",
      "Frais CLIK AFRIC",
      "Frais Greffe",
      "Frais OGAPI",
      "Frais Journal officiel",
      "Total",
    ]
  };

  switch (choix) {
    case "journal":
      return header.journal;
      break;
    case "ogapi":
      return header.ogapi;
      break;
    case "greffe":
      return header.greffe;
      break;
    case "click_afric":
      return header.fraisClickAfric;
      break;
    case "dgi":
      return header.dgi;
      break;
    case "droits":
      return header.droits;
      break;
    case "penalites":
      return header.penalites;
      break;
    case "timbres":
      return header.timbres;
      break;
    case "anpi":
      return header.anpi;
      break;
    case "tout-sans-anpi":
      return header.allWithoutAnpi;
      break;
    case "restant-partenaire":
      return header.restantPartenaire;
      break;
    case "etat":
      return header.etat;
      break;
    case "versement":
      return header.versement;
      break;  
    case "tout":
      return header.allGni;
      break;
    default:
      return header.all;
      break;
  }
};
