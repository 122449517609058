import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Modal } from "react-bootstrap";
import RecetteContext from "../context/RecetteContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";

const RecetteUpdate = ({ onShow, change, recette }) => {
  const recetteContext = useContext(RecetteContext);
  const {
    selectedRecette,
    updateGni,
    clearRecette,
    clearSeletedRecette,
    recettes,
  } = recetteContext;
  const [startDate, setStartDate] = useState();
  const [newRecette, setnewRecette] = useState({
    id: "",
    dateComptable: startDate,
    quantite: "",
    ordre: "",
    formExpress: "",
    locationAutre: "",
    fraisAnpi: "",
    droitGni: "",
    fraisAnpiPart: "0",
    fraisAnpiTotal: "0",
    droits: "",
    penalite: "",
    timbres: "",
    total: "0",
    fraisClickAfric: "",
    greffe: "",
    ogapi: "",
    insertionJournal: "",
    totalFinal: "",
  });

  const [errors, setErrors] = useState(false);
  const [droitsTemp, setDroitsTemp] = useState(0);
  const [penalTemp, setPenalTemp] = useState(0);
  const [totalDGI, setTotalDGI] = useState(0);
  const [timbreTemp, setTimbreTemp] = useState(0);

  const [totalAnpiPart, setTotalAnpiPart] = useState(0);
  const [totalLigne, setTotalLigne] = useState(0);
  const [expressTemp, setExpressTemp] = useState(0);
  const [locationTemp, setLocationTemp] = useState(0);
  const [dfdeTemp, setDfdeTemp] = useState(0);
  const [greffeTemp, setGreffeTemp] = useState(0);
  const [ogapiTemp, setOgapiTemp] = useState(0);
  const [journalTemp, setJournalTemp] = useState(0);
  const [fraisAnpiTotalTemp, setFraisAnpiTotalTemp] = useState(0);
  const [fraisClickAfricTemp, setFraisClickAfricTemp] = useState(0);
  const [droitGniTemp, setDroitGniTemp] = useState(0);
  const [fraisAnpiTemp, setFraisAnpiTemp] = useState(0);
  
  const [colorQt, setcolorQt] = useState("none");
  const [colorOrdre, setColorOrdre] = useState("none");

  const qtRef = useRef(null);
  const inputOrdreRef = useRef(null);
  let formExpressRef = useRef(null);
  let locationAutreRef = useRef(null);
  let dfdeRef = useRef(null);
  let penaliteRef = useRef(null);
  let droitsRef = useRef(null);
  let timbreRef = useRef(null);
  let greffeRef = useRef(null);
  let ogapiRef = useRef(null);
  let insertionJournalRef = useRef(null);
  let fraisClickAfricRef = useRef(null);
  let fraisAnpiRef = useRef(null);
  let droitGniRef = useRef(null);

  const {
    id,
    dateComptable,
    quantite,
    ordre,
    formExpress,
    locationAutre,
    fraisAnpi,
    droitGni,
    fraisAnpiPart,
    fraisAnpiTotal,
    fraisClickAfric,
    droits,
    penalite,
    timbres,
    total,
    greffe,
    ogapi,
    insertionJournal,
    totalFinal,
    createdAt,
    updatedAt,
  } = newRecette;

  useEffect(() => {
    setnewRecette(recette);
    setStartDate(moment(recette.dateComptable).toDate());
  }, [recette]);

  useEffect(() => {
    handleTotalDGI();
    handleTotalLigne();
  }, [
    droitsTemp,
    penalTemp,
    expressTemp,
    locationTemp,
    dfdeTemp,
    greffeTemp,
    ogapiTemp,
    journalTemp,
    timbreTemp,
    fraisClickAfricTemp,
    fraisAnpiTotalTemp,
  ]);

  useEffect(() => {
    handleFraisAnpi();
    handleFraisAnpiTotal();
  }, [droitGniTemp, fraisAnpiTemp, totalAnpiPart]);

  const handleModal = () => {
    change();
    clearSeletedRecette();
  };

  const handleValue = (e) => {
    setnewRecette({ ...newRecette, [e.target.name]: e.target.value });
  };

  const onChangeDate = (date) => {
    setStartDate(date);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    newRecette.dateComptable = startDate;

    newRecette.droits = droitsTemp;
    newRecette.penalite = penalTemp;
    newRecette.timbres = timbreTemp;

    newRecette.total = totalDGI;

    newRecette.formExpress = expressTemp;
    newRecette.locationAutre = locationTemp;

    newRecette.fraisAnpi = fraisAnpiTemp;
    newRecette.droitGni = droitGniTemp;
    newRecette.fraisAnpiPart = totalAnpiPart;
    newRecette.fraisAnpiTotal = fraisAnpiTotalTemp;
    newRecette.fraisClickAfric = fraisClickAfricTemp;

    newRecette.greffe = greffeTemp;
    newRecette.ogapi = ogapiTemp;
    newRecette.insertionJournal = journalTemp;
    newRecette.totalFinal = totalLigne;
    newRecette.updatedAt = new Date();

    updateGni(newRecette);

    clearRecette();
    handleModal();
  };

  const handleTotalDGI = () => {
    if (isNaN(droitsTemp)) {
      setDroitsTemp(0);
    }
    if (isNaN(penalTemp)) {
      setPenalTemp(0);
    }
    if (isNaN(timbreTemp)) {
      setTimbreTemp(0);
    }
    setTotalDGI(droitsTemp + penalTemp + timbreTemp);
  };

  const handleFraisAnpi = () => {
    if (isNaN(droitGniTemp)) {
      setDroitGniTemp(0);
    }
    setTotalAnpiPart(droitGniTemp * 0.6);
  };

  const handleFraisAnpiTotal = () => {
    if (isNaN(fraisAnpiTemp)) {
      setFraisAnpiTemp(0);
    }
    setFraisAnpiTotalTemp(fraisAnpiTemp + totalAnpiPart);
  };

  const handleDroitTemp = (e) => {
    setDroitsTemp(parseInt(e));
  };

  const handleFraisAnpiTemp = (e) => {
    setFraisAnpiTemp(parseInt(e));
  };

  const handlePenalTemp = (e) => {
    setPenalTemp(parseInt(e));
  };

  const handleDroitGniTemp = (e) => {
    setDroitGniTemp(parseInt(e));
  };

  const handleTimbreTemp = (e) => {
    setTimbreTemp(parseInt(e));
  };

  // total ligne
  const handleexpressTemp = (e) => {
    setExpressTemp(parseInt(e));
  };

  const handleLocationTemp = (e) => {
    setLocationTemp(parseInt(e));
  };

  const handleGreffeTemp = (e) => {
    setGreffeTemp(parseInt(e));
  };

  const handleOgapiTemp = (e) => {
    setOgapiTemp(parseInt(e));
  };

  const handleJournalTemp = (e) => {
    setJournalTemp(parseInt(e));
  };

  const handleFraisClickAfricTemp = (e) => {
    setFraisClickAfricTemp(parseInt(e));
  };

  const handleTotalLigne = () => {
    if (isNaN(expressTemp)) {
      setExpressTemp(0);
    }
    if (isNaN(locationTemp)) {
      setLocationTemp(0);
    }
    if (isNaN(dfdeTemp)) {
      setDfdeTemp(0);
    }
    if (isNaN(droitsTemp)) {
      setDroitsTemp(0);
    }
    if (isNaN(penalTemp)) {
      setPenalTemp(0);
    }
    if (isNaN(greffeTemp)) {
      setGreffeTemp(0);
    }
    if (isNaN(ogapiTemp)) {
      setOgapiTemp(0);
    }
    if (isNaN(journalTemp)) {
      setJournalTemp(0);
    }
    if (isNaN(timbreTemp)) {
      setTimbreTemp(0);
    }
    if (isNaN(fraisClickAfricTemp)) {
        setFraisClickAfricTemp(0);
    }

    setTotalLigne(
        expressTemp +
        locationTemp +
        fraisAnpiTotalTemp +
        droitsTemp +
        penalTemp +
        greffeTemp +
        ogapiTemp +
        journalTemp +
        timbreTemp +
        fraisClickAfricTemp
    );
  };

  const handleQuantite = (e) => {
    var q = null;
    q = recettes.find(
      (recette) => parseInt(recette.quantite) === parseInt(e.target.value)
    );
    if (typeof q === "undefined") {
      setcolorQt("white");
      setErrors(false);
    } else {
      setcolorQt("red");
      setErrors(true);
    }
  };

  const handleOrdre = (e) => {
    var q = null;
    q = recettes.find(
      (recette) => parseInt(recette.ordre) === parseInt(e.target.value)
    );
    if (typeof q === "undefined") {
      setColorOrdre("white");
      setErrors(false);
    } else {
      setColorOrdre("red");
      setErrors(true);
    }
  };

  return (
    <Fragment>
      <Modal size="lg" show={onShow} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mise à jour recette</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "grey" }}>
          <form onSubmit={handleUpdate}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="dateComptable">Date Comptable</label>
                <br />
                <DatePicker
                  className="form-control form-control-sm"
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={onChangeDate}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="NºQA">NºQA</label>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  required
                  style={{ backgroundColor: colorQt }}
                  name="quantite"
                  onKeyUp={handleQuantite}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      inputOrdreRef.current.focus();
                    }
                  }}
                  ref={qtRef}
                  value={quantite}
                  onChange={handleValue}
                  placeholder="NºQA"
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="NºOR">NºOR</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  style={{ backgroundColor: colorOrdre }}
                  name="ordre"
                  onKeyUp={handleOrdre}
                  required
                  value={ordre}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      formExpressRef.focus();
                    }
                  }}
                  ref={inputOrdreRef}
                  onChange={handleValue}
                  placeholder="NºOR"
                />
              </div>
            </div>
            <br />
            <h5>Recette anpi-gabon</h5>
            <br />
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="FormuleExpress">Formule Express</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Formule Express"
                  name="formExpress"
                  value={formExpress}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleexpressTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      locationAutreRef.focus();
                    }
                  }}
                  ref={formExpressRef}
                  getInputRef={(el) => (formExpressRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="LocationAutres">Location et autres</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Formule Express"
                  name="locationAutre"
                  value={locationAutre}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleLocationTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      fraisAnpiRef.focus();
                    }
                  }}
                  ref={locationAutreRef}
                  getInputRef={(el) => (locationAutreRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
            </div>
            <br />
            <h5>GNI</h5>
            <br />
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="Droit">Frais ANPI</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Frais anpi"
                  name="fraisAnpi"
                  value={fraisAnpi}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleFraisAnpiTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      droitGniRef.focus();
                    }
                  }}
                  ref={fraisAnpiRef}
                  getInputRef={(el) => (fraisAnpiRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="Penalite">Droits GNI</label>

                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Droits gni"
                  name="droitGni"
                  value={droitGni}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleDroitGniTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      droitsRef.focus();
                    }
                  }}
                  ref={droitGniRef}
                  getInputRef={(el) => (droitGniRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="Timbre">Frais ANPI (60%)</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Frais ANPI (60%)"
                  disabled
                  name="timbres"
                  value={totalAnpiPart}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="totalDGI">Frais ANPI total</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  disabled
                  placeholder="Total"
                  value={fraisAnpiTotalTemp}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
            </div>
            <br />

            <h5>Droit d'enregistrement (DGI)</h5>
            <br />
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="Droit">Droit (code 38)</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Droit (code 38)"
                  name="droits"
                  value={droits}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleDroitTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      penaliteRef.focus();
                    }
                  }}
                  ref={droitsRef}
                  getInputRef={(el) => (droitsRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="Penalite">Penalite (code 81)</label>

                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Penalite (code 81)"
                  name="penalite"
                  value={penalite}
                  onValueChange={(values) => {
                    const { value } = values;
                    handlePenalTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      timbreRef.focus();
                    }
                  }}
                  ref={penaliteRef}
                  getInputRef={(el) => (penaliteRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="Timbre">Timbres (code 75)</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Timbre (code 75)"
                  name="timbres"
                  value={timbres}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleTimbreTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      fraisClickAfricRef.focus();
                    }
                  }}
                  ref={timbreRef}
                  getInputRef={(el) => (timbreRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="totalDGI">Total DGI</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  disabled
                  placeholder="Total"
                  value={totalDGI}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
            </div>
            <br />

            <br />
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="FraisGreffe">Frais CLIK AFRIC</label>

                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Frais CLIK AFRIC"
                  name="fraisClickAfric"
                  value={fraisClickAfric}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleFraisClickAfricTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      greffeRef.focus();
                    }
                  }}
                  ref={fraisClickAfricRef}
                  getInputRef={(el) => (fraisClickAfricRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="FraisGreffe">Frais GREFFE</label>

                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Frais de Greffe"
                  name="greffe"
                  value={greffe}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleGreffeTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      ogapiRef.focus();
                    }
                  }}
                  ref={greffeRef}
                  getInputRef={(el) => (greffeRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="FraisOgapi">Frais OGAPI</label>

                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Frais Ogapi"
                  name="ogapi"
                  value={ogapi}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleOgapiTemp(value);
                  }}
                  onKeyPress={(e) => {
                    const { key } = e;
                    if (key === "Enter") {
                      e.preventDefault();
                      insertionJournalRef.focus();
                    }
                  }}
                  ref={ogapiRef}
                  getInputRef={(el) => (ogapiRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="InsertionJournalOfficiel">
                  Frais Journal Officiel
                </label>
                <NumberFormat
                  className="form-control form-control-sm"
                  placeholder="Insertion journal officiel"
                  name="insertionJournal"
                  value={insertionJournal}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleJournalTemp(value);
                  }}
                  ref={insertionJournalRef}
                  getInputRef={(el) => (insertionJournalRef = el)}
                  onChange={handleValue}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="total">Total</label>
                <NumberFormat
                  className="form-control form-control-sm"
                  disabled
                  placeholder="Total"
                  value={totalLigne}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </div>
            </div>
            <button disabled={errors} type="submit" className="btn btn-primary">
              Enregistrer
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default RecetteUpdate;
