import React, { useState, useContext, useEffect } from "react";
import RecetteContext from "../context/RecetteContext";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ShowTable from "./ShowTable";
import JsfPdfDoc from "../Etats/JsPdfDoc";
import { filtrerDate, sortDate } from "../utility/Utils";
import OptionsTable from "./optionsTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader } from '@fortawesome/free-solid-svg-icons';

const RecetteJour = (props) => {
  registerLocale("fr", fr);
  const recetteContext = useContext(RecetteContext);
  const {} = recetteContext;
  const [startDate, setStartDate] = useState(null);
  const [selectPartner, setSelectPartner] = useState("tout");
  const [listMontant, setListMontant] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  let total;
  let [filterRecettes, setFilterRecettes] = useState([]);
  let { recettes } = props;
  useEffect(() => {
    if (startDate !== null && lastDate !== null) {
      console.log(recettes);
      setFilterRecettes(sortDate(filtrerDate(recettes, startDate, lastDate)));
    }
  }, [startDate, lastDate]);

  useEffect(() => {
    recetteMoisFiltered(filterRecettes);
  }, [filterRecettes]);

  const onChangeDate = (date) => {
    setStartDate(date);
  };

  const onChangeLastDate = (lastDate) => {
    setLastDate(lastDate);
  };

  const handlePartner = (e) => {
    setSelectPartner(e.target.value);
  };

  const recetteMoisFiltered = (filterRecette) => {
    let object = {};
    let montantMois = [];

    object.formExpress = 0;
    filterRecette.forEach((recette) => {
      object.formExpress += recette.formExpress;
    });

    object.locationAutre = 0;
    filterRecette.forEach((recette) => {
      object.locationAutre += recette.locationAutre;
    });

    object.fraisAnpi = 0;
    filterRecette.forEach((recette) => {
      object.fraisAnpi += recette.fraisAnpi;
    });

    object.droitGni = 0;
    filterRecette.forEach((recette) => {
      object.droitGni += recette.droitGni;
    });

    object.fraisAnpiPart = 0;
    filterRecette.forEach((recette) => {
      object.fraisAnpiPart += recette.fraisAnpiPart;
    });

    object.fraisAnpiTotal = 0;
    filterRecette.forEach((recette) => {
      object.fraisAnpiTotal += recette.fraisAnpiTotal;
    });

    object.droits = 0;
    filterRecette.forEach((recette) => {
      object.droits += recette.droits;
    });

    object.penalite = 0;
    filterRecette.forEach((recette) => {
      object.penalite += recette.penalite;
    });

    object.timbres = 0;
    filterRecette.forEach((recette) => {
      object.timbres += recette.timbres;
    });

    object.total = 0;
    filterRecette.forEach((recette) => {
      object.total += recette.total;
    });

    object.fraisClickAfric = 0;
    filterRecette.forEach((recette) => {
      object.fraisClickAfric += recette.fraisClickAfric;
    });

    object.greffe = 0;
    filterRecette.forEach((recette) => {
      object.greffe += recette.greffe;
    });

    object.ogapi = 0;
    filterRecette.forEach((recette) => {
      object.ogapi += recette.ogapi;
    });

    object.insertionJournal = 0;
    filterRecette.forEach((recette) => {
      object.insertionJournal += recette.insertionJournal;
    });

    object.totalFinal = 0;
    filterRecette.forEach((recette) => {
      object.totalFinal += recette.totalFinal;
    });

    montantMois.push(object);
    setListMontant(montantMois);
  };

  return (
    <div className="card mt-3">
      <div className="px-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2"> <FontAwesomeIcon icon={faBookReader} /> Recettes Jour</h1>
          <div class="btn-toolbar mb-2 mb-md-0">
            <div class="btn-group me-2">
              <OptionsTable
                choix={selectPartner}
                pdfTitle="SUIVI DES RECETTES DU GUICHET DE L'INVESTISSEMENT"
                datas={filterRecettes}
                listMontant={listMontant}
              />
            </div>
          </div>
        </div>

        <Row>
          <Col>
            <div style={{ margin: "20px" }}>
              <label>Selectionner la date : </label>
              {"  "}
              <br />
              <DatePicker
                className="form-control form-control-sm border border-secondary"
                selected={startDate}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                onChange={onChangeDate}
                selectsStart
                startDate={startDate}
                endDate={lastDate}
              />

              <br />
              <DatePicker
                className="form-control form-control-sm border border-secondary mt-2"
                selected={lastDate}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                onChange={onChangeLastDate}
                selectsEnd
                startDate={startDate}
                endDate={lastDate}
              />
            </div>
          </Col>

          <Col>
            <div style={{ margin: "20px" }}>
              <label for="selectPartenaires">Trier par partenaire : </label>
              {"  "}
              <select
                class="form-control form-control-sm border border-secondary"
                id="selectPartenaires"
                value={selectPartner}
                onChange={handlePartner}
              >
                <option value="tout">Tout</option>
                <option value="anpi">Anpi</option>
                <option value="dgi">DGI</option>
                <option value="droits">--Droits</option>
                <option value="penalites">--Penalités</option>
                <option value="timbres">--Timbres</option>
                <option value="click_afric">CLIK AFRIC</option>
                <option value="greffe">Greffe</option>
                <option value="ogapi">Ogapi</option>
                <option value="journal">Insertion Journal Officiel</option>
              </select>
            </div>
          </Col>
        </Row>

        <ShowTable
          choix={selectPartner}
          filterRecettes={filterRecettes}
          listMontant={listMontant}
        />
      </div>
    </div>
  );
};

export default RecetteJour;
