import React, { useState, useContext, useEffect } from "react";
import RecetteContext from "../context/RecetteContext";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ShowTable from "./ShowTable";
import JsfPdfDoc from "../Etats/JsPdfDoc";
import PartnersMois from "./PartnersMois";
import { filtrerDate, sortDate } from "../utility/Utils";
import moment from "moment";
import OptionsTable from "./optionsTable";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookReader } from '@fortawesome/free-solid-svg-icons'

const RecetteMois = (props) => {
  registerLocale("fr", fr);
  let total;
  const recetteContext = useContext(RecetteContext);
  const [listMontant, setListMontant] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [selectPartner, setSelectPartner] = useState();
  //const [restantPartenaire, setrestantPartenaire] = useState(0);
  const [totalPart, setTotalPart] = useState([]);
  /*  let [
    montantPartenairesMoisBetweenDate,
    setMontantPartenairesMoisBetweenDate,
  ] = useState(0); */

  let [filterRecettes, setFilterRecettes] = useState([]);
  let { recettes, recettePartenaires, restantPartenaires } = props;
  const {
    getRecettePartenaires,
    addRecettePartenaires,
    deleteRecettePartenaires,
    //recettePartenaires,
  } = recetteContext;

  useEffect(() => {
    if (startDate !== null && lastDate !== null) {
      setFilterRecettes(
        sortDate(filtrerDate(recettes, startDate, lastDate), "")
      );
    }
  }, [startDate, lastDate]);

  useEffect(() => {
    recetteMoisFiltered(filterRecettes);
  }, [filterRecettes]);

  useEffect(() => {
    console.log();
  }, [recettePartenaires]);

  useEffect(() => {
    //getRecettePartenaires();
    //console.log(recettePartenaires);
    //bilanPartenaires(listMontant, lastDate);
    calculerPartenaire(listMontant, lastDate);
  }, [listMontant]);

  // Permet d'avoir le total de chaque rubrique de recette (formExpress, locationAutre, ...)
  const recetteMoisFiltered = (filterRecettes) => {
    console.log(calculRecetteMois(filterRecettes));
    setListMontant(calculRecetteMois(filterRecettes));
  };

  const calculRecetteMois = (filterRecette) => {
    let object = {};
    let montantMois = [];

    object.formExpress = 0;
    filterRecette.forEach((recette) => {
      object.formExpress += recette.formExpress;
    });

    object.locationAutre = 0;
    filterRecette.forEach((recette) => {
      object.locationAutre += recette.locationAutre;
    });

    object.fraisAnpi = 0;
    filterRecette.forEach((recette) => {
      object.fraisAnpi += recette.fraisAnpi;
    });

    object.droitGni = 0;
    filterRecette.forEach((recette) => {
      object.droitGni += recette.droitGni;
    });

    object.fraisAnpiPart = 0;
    filterRecette.forEach((recette) => {
      object.fraisAnpiPart += recette.fraisAnpiPart;
    });

    object.fraisAnpiTotal = 0;
    filterRecette.forEach((recette) => {
      object.fraisAnpiTotal += recette.fraisAnpiTotal;
    });

    object.droits = 0;
    filterRecette.forEach((recette) => {
      object.droits += recette.droits;
    });

    object.penalite = 0;
    filterRecette.forEach((recette) => {
      object.penalite += recette.penalite;
    });

    object.timbres = 0;
    filterRecette.forEach((recette) => {
      object.timbres += recette.timbres;
    });

    object.total = 0;
    filterRecette.forEach((recette) => {
      object.total += recette.total;
    });

    object.fraisClickAfric = 0;
    filterRecette.forEach((recette) => {
      object.fraisClickAfric += recette.fraisClickAfric;
    });

    object.greffe = 0;
    filterRecette.forEach((recette) => {
      object.greffe += recette.greffe;
    });

    object.ogapi = 0;
    filterRecette.forEach((recette) => {
      object.ogapi += recette.ogapi;
    });

    object.insertionJournal = 0;
    filterRecette.forEach((recette) => {
      object.insertionJournal += recette.insertionJournal;
    });

    object.totalFinal = 0;
    filterRecette.forEach((recette) => {
      object.totalFinal += recette.totalFinal;
    });

    /* if(object.formExpress === 0 && object.totalFinal === 0 
      && object.insertionJournal === 0 && object.ogapi === 0
      && object.greffe === 0 && object.fraisClickAfric === 0
      && object.total === 0 && object.timbres === 0 && object.penalite === 0
      && ) */
    montantMois.push(object);

    return montantMois;
  };

  /*
    - get bilan of last month en fonction de la date fin selectionné dans le calendrier
    - Faire la somme du bilan avec listMontant
    - sortir le total partenaire
    */

  const calculerPartenaire = (listRecetteMoisActuel, lastDate) => {
    console.log("recette mois actuel");
    console.log(listRecetteMoisActuel);
    if (
      startDate !== null &&
      lastDate !== null &&
      new Date(startDate).getMonth() === new Date(lastDate).getMonth()
    ) {
      let listRecetteBase = [];
      let recetteDuMoisPrecedent = [];
      let sommeDesRecettesTotalDuMois = [];
      let moisPasse = null;
      let verifRecetteMoisActuel = [];
      // verifie si listRecetteBase contient contient des donnees en cours
      console.log("recettePartenaires");
      console.log(recettePartenaires);

      if (listRecetteBase.length === 0) {
        listRecetteBase = recettePartenaires;
        if (
          listRecetteBase.length > 0 &&
          new Date(lastDate) > new Date(startDate)
        ) {
          /* 
          recuperer les donnees qui correspondent au mois précédent
          - determiner le mois precedent a partir du mois selectionne
          - Faire un filtre sur le mois pour recuperer la ligne de la recette a partir de listRecetteBase 
        */

          // Verification si la total partenaire inserer la premiere fois  existe deja dans listRecetteBase
          // donc plus d'insertion si la base de donne
          verifRecetteMoisActuel = listRecetteBase.filter(
            (data) =>
              moment(data.dateComptable).month() === moment(lastDate).month()
          );

          if (verifRecetteMoisActuel.length === 0) {
            // selection du mois precedent en question
            recetteDuMoisPrecedent = listRecetteBase.filter(
              (data) =>
                moment(data.dateComptable).month() ===
                moment(lastDate).subtract(1, "months").month()
            );

            /* 
        - Ajouter les données du mois précédent avec les données du mois actuel
        - Ajouter le resultat dans listRecetteBase
        - Ajouter le resultat dans la base de donne
      */

            if (
              recetteDuMoisPrecedent.length > 0 &&
              listRecetteMoisActuel.length >= 0 &&
              new Date(moment(startDate).format("L")).getMonth() ===
                new Date(moment(lastDate).format("L")).getMonth()
            ) {
              sommeDesRecettesTotalDuMois = listRecetteMoisActuel.map(
                (recette) => {
                  return {
                    dateComptable: lastDate,
                    total: recetteDuMoisPrecedent[0].total + recette.total,
                    greffe: recetteDuMoisPrecedent[0].greffe + recette.greffe,
                    clikAfric:
                      recetteDuMoisPrecedent[0].clikAfric +
                      recette.fraisClickAfric,
                    ogapi: recetteDuMoisPrecedent[0].ogapi + recette.ogapi,
                    journal:
                      recetteDuMoisPrecedent[0].journalOfficiel +
                      recette.insertionJournal,
                    totalPartenaire:
                      recetteDuMoisPrecedent[0].total +
                      recette.total +
                      (recetteDuMoisPrecedent[0].greffe + recette.greffe) +
                      (recetteDuMoisPrecedent[0].ogapi + recette.ogapi) +
                      (recetteDuMoisPrecedent[0].journalOfficiel +
                        recette.insertionJournal),
                  };
                }
              );

              console.log("date X =");

              console.log(moment(lastDate).locale("fr").format("DD/MM/YYYY"));
              addRecettePartenaires({
                //dateComptable: moment(lastDate).format("L"),
                dateComptable: lastDate,
                total: sommeDesRecettesTotalDuMois[0].total,
                clikAfric: sommeDesRecettesTotalDuMois[0].clikAfric,
                greffe: sommeDesRecettesTotalDuMois[0].greffe,
                ogapi: sommeDesRecettesTotalDuMois[0].ogapi,
                journalOfficiel: sommeDesRecettesTotalDuMois[0].journal,
              });

              listRecetteBase.push({
                id: listRecetteBase[listRecetteBase.length - 1],
                dateComptable: lastDate,
                total: sommeDesRecettesTotalDuMois[0].total,
                clikAfric: sommeDesRecettesTotalDuMois[0].clikAfric,
                greffe: sommeDesRecettesTotalDuMois[0].greffe,
                ogapi: sommeDesRecettesTotalDuMois[0].ogapi,
                journalOfficiel: sommeDesRecettesTotalDuMois[0].journal,
              });

              console.log("idddd");
              console.log(listRecetteBase);
              setTotalPart(sommeDesRecettesTotalDuMois);
            } else {
              //alert("Il n'y a pas de donnees sur le mois précedent");
              // recuperer les recettes pour le mois actuel

              let recetteSansReport = [];
              recetteSansReport = listRecetteBase.filter((data) => {
                if (
                  new Date(
                    moment(data.dateComptable).format("L")
                  ).getMonth() ===
                    new Date(moment(lastDate).format("L")).getMonth()
                ) {
                  return true;
                }
              });

              if (recetteSansReport.length > 0) {
                sommeDesRecettesTotalDuMois = [
                  {
                    total: recetteSansReport[0].total,
                    greffe: recetteSansReport[0].greffe,
                    ogapi: recetteSansReport[0].ogapi,
                    journal: recetteSansReport[0].journalOfficiel,
                    totalPartenaire:
                      recetteSansReport[0].total +
                      recetteSansReport[0].greffe +
                      recetteSansReport[0].ogapi +
                      recetteSansReport[0].journalOfficiel,
                  },
                ];
              } else {
                sommeDesRecettesTotalDuMois = [
                  {
                    total: 0,
                    greffe: 0,
                    ogapi: 0,
                    journal: 0,
                    totalPartenaire: 0,
                  },
                ];
              }
              setTotalPart(sommeDesRecettesTotalDuMois);
            }
          } else {
            //alert("cette recette existe deja dans le tableau");
            console.log("cette recette existe deja dans le tableau");
            /* sommeDesRecettesTotalDuMois = listRecetteMoisActuel.map(
              (recette) => {
                return {
                  dateComptable: lastDate,
                  total: recetteDuMoisPrecedent[0].total + recette.total,
                  greffe: recetteDuMoisPrecedent[0].greffe + recette.greffe,
                  ogapi: recetteDuMoisPrecedent[0].ogapi + recette.ogapi,
                  journal:
                    recetteDuMoisPrecedent[0].journalOfficiel +
                    recette.insertionJournal,
                  totalPartenaire:
                    recetteDuMoisPrecedent[0].total +
                    recette.total +
                    (recetteDuMoisPrecedent[0].greffe + recette.greffe) +
                    (recetteDuMoisPrecedent[0].ogapi + recette.ogapi) +
                    (recetteDuMoisPrecedent[0].journalOfficiel +
                      recette.insertionJournal),
                };
              }
            ); */
            verifRecetteMoisActuel = listRecetteBase.filter(
              (data) =>
                moment(data.dateComptable).month() === moment(lastDate).month()
            );
            console.log("date a supprimer");
            console.log(
              moment(verifRecetteMoisActuel[0].dateComptable).format()
            );
            if (
              moment(lastDate).isAfter(verifRecetteMoisActuel[0].dateComptable)
            ) {
              console.log("il doit avoir mise a jour");
              console.log(
                moment(verifRecetteMoisActuel[0].dateComptable).toISOString()
              );

              verifRecetteMoisActuel.forEach((d) => {
                moment(d.dateComptable).toISOString();
                deleteRecettePartenaires(moment(d.dateComptable).toISOString());
              });

              // selection du mois precedent en question
              recetteDuMoisPrecedent = listRecetteBase.filter(
                (data) =>
                  moment(data.dateComptable).month() ===
                  moment(lastDate).subtract(1, "months").month()
              );

              sommeDesRecettesTotalDuMois = listRecetteMoisActuel.map(
                (recette) => {
                  return {
                    dateComptable: lastDate,
                    total: recetteDuMoisPrecedent[0].total + recette.total,
                    clikAfric:
                      recetteDuMoisPrecedent[0].clikAfric +
                      recette.fraisClickAfric,
                    greffe: recetteDuMoisPrecedent[0].greffe + recette.greffe,
                    ogapi: recetteDuMoisPrecedent[0].ogapi + recette.ogapi,
                    journal:
                      recetteDuMoisPrecedent[0].journalOfficiel +
                      recette.insertionJournal,
                    totalPartenaire:
                      recetteDuMoisPrecedent[0].total +
                      recette.total +
                      (recetteDuMoisPrecedent[0].greffe + recette.greffe) +
                      (recetteDuMoisPrecedent[0].ogapi + recette.ogapi) +
                      (recetteDuMoisPrecedent[0].journalOfficiel +
                        recette.insertionJournal),
                  };
                }
              );
              console.log(sommeDesRecettesTotalDuMois);
              addRecettePartenaires({
                dateComptable: lastDate,
                total: sommeDesRecettesTotalDuMois[0].total,
                clikAfric: sommeDesRecettesTotalDuMois[0].clikAfric,
                greffe: sommeDesRecettesTotalDuMois[0].greffe,
                ogapi: sommeDesRecettesTotalDuMois[0].ogapi,
                journalOfficiel: sommeDesRecettesTotalDuMois[0].journal,
              });

              listRecetteBase.push({
                id: listRecetteBase[listRecetteBase.length - 1].id + 1,
                dateComptable: lastDate,
                total: sommeDesRecettesTotalDuMois[0].total,
                clikAfric: sommeDesRecettesTotalDuMois[0].clikAfric,
                greffe: sommeDesRecettesTotalDuMois[0].greffe,
                ogapi: sommeDesRecettesTotalDuMois[0].ogapi,
                journalOfficiel: sommeDesRecettesTotalDuMois[0].journal,
              });
            } else {
              // On garde l'ancienne valeure
              console.log("On garde l'ancienne valeure");
              sommeDesRecettesTotalDuMois = verifRecetteMoisActuel.map(
                (recette) => {
                  return {
                    dateComptable: recette.dateComptable,
                    total: recette.total,
                    greffe: recette.greffe,
                    ogapi: recette.ogapi,
                    clikAfric: recette.fraisClickAfric,
                    journal: recette.journalOfficiel,
                    totalPartenaire:
                      recette.total +
                      recette.greffe +
                      recette.ogapi +
                      recette.journalOfficiel,
                  };
                }
              );
            }

            setTotalPart(sommeDesRecettesTotalDuMois);
          }
        } else {
          console.log("ListRecetteBase est vide ou n'a pas pu etre chargé ");
          // console.log("Creer des donnees avant compilation")
          NotificationManager.error(
            "Effectuer le calcul de report avant tout calcul !",
            " Error message",
            5000
          );
          /*   console.log(lastDate);
          addRecettePartenaires({
            dateComptable: new Date(lastDate),
            total: 0,
            greffe: 0,
            ogapi: 0,
            journalOfficiel: 0,
          });  */
          setTotalPart([
            {
              total: 0,
              clikAfric: 0,
              greffe: 0,
              ogapi: 0,
              journal: 0,
              totalPartenaire: 0,
            },
          ]);
        }
      } else {
      }
    }
  };

  const handlePartner = (e) => {
    setSelectPartner(e.target.value);
  };

  const handleDate = (date) => {
    setStartDate(date);
  };

  const onChangeLastDate = (lastDate) => {
    setLastDate(lastDate);
  };

  return (
    <div className="card mt-3">
      <div className="px-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2"> <FontAwesomeIcon icon={faBookReader} /> Recettes Mois</h1>
          <div class="btn-toolbar mb-2 mb-md-0">
            <div class="btn-group me-2">
              <OptionsTable
                choix={selectPartner}
                pdfTitle="SUIVI DES RECETTES DU GUICHET DE L'INVESTISSEMENT"
                datas={filterRecettes}
                listMontant={listMontant}
              />
            </div>
          </div>
        </div>

        <Row>
          <Col>
            <div style={{ margin: "20px" }}>
              <label>Selectionner date : </label>
              {"  "}
              <br />
              <DatePicker
                className="form-control  form-control-sm border border-secondary"
                selected={startDate}
                dateFormat="dd/MM/yyyy"
                locale="fr"
                //showMonthYearPicker
                //showFullMonthYearPicker
                onChange={handleDate}
                selectsStart
                startDate={startDate}
                endDate={lastDate}
              />
              <br />
              <DatePicker
                className="form-control  form-control-sm border border-secondary mt-2"
                selected={lastDate}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                //showMonthYearPicker
                //showFullMonthYearPicker
                onChange={onChangeLastDate}
                selectsEnd
                startDate={startDate}
                endDate={lastDate}
              />
            </div>
          </Col>

          <Col>
            <div style={{ margin: "20px" }}>
              <label for="selectPartenaires">Trier par partenaire : </label>
              {"  "}
              <select
                class="form-control form-control-sm border border-secondary"
                id="selectPartenaires"
                value={selectPartner}
                onChange={handlePartner}
              >
                <option value="tout">Tout</option>
                <option value="anpi">Anpi</option>
                <option value="dgi">DGI</option>
                <option value="droits">--Droits</option>
                <option value="penalites">--Pénalités</option>
                <option value="timbres">--Timbres</option>
                <option value="click_afric">CLIK AFRIC</option>
                <option value="greffe">Greffe</option>
                <option value="ogapi">Ogapi</option>
                <option value="journal">Insertion Journal Officiel</option>
                <option value="tout-sans-anpi">Partenaires (sans ANPI)</option>
                <option value="etat">Etat</option>
              </select>
            </div>
          </Col>
          {/*  <Col>
          <div style={{ margin: "20px" }}>
            <label>Options : </label>
            <Row>
              <Col>
                <JsfPdfDoc source="table-to-xls" title="SUIVI DES RECETTES" />
              </Col>
              <Col>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-primary download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Telecharger excel"
                />
              </Col>
            </Row>
          </div>
        </Col> */}
        </Row>

        {selectPartner === "tout-sans-anpi" ? (
          <PartnersMois
            dateCompt={startDate}
            listMontant={listMontant}
            bilanPartnaire={totalPart}
            //montantPartenairesMois={montantPartenairesMoisBetweenDate}
            restantPartenaires={restantPartenaires}
          />
        ) : null}

        <ShowTable
          choix={selectPartner}
          filterRecettes={filterRecettes}
          listMontant={listMontant}
        />
        <NotificationContainer />
      </div>
    </div>
  );
};

export default RecetteMois;
