import React, { useEffect, useState, Fragment, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../logo-anpi.png";
import jwt_decode from "jwt-decode";
import AuthContext from "../context/auth/AuthContext";
import "../css/navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faEdit,
  faNewspaper,
  faClipboardCheck,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Routes from "../routing/MainRoutes";
import Alerts from "./Alerts";
import Register from "./Register";
import Login from "./Login";

const Landing = (props) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loadUser, logout } = authContext;
  const [departement, setDepartement] = useState("");
  const racinePrivateUrl = "dashboard";

  useEffect(() => {
    console.log("navbar");

    try {
      const token = localStorage.usertoken;

      const decoded = jwt_decode(token);

      setDepartement(decoded.departement);
    } catch (error) {
      console.log(error);
    }
  }, [isAuthenticated]);

  const logOut = (e) => {
    e.preventDefault();
    logout();
    props.history.push(`/`);
  };

  const loginRegLink = () => (
    <Fragment>
      {/*  <ul className="navbar-nav  mr-auto">
      <li className="nav-item">
        <Link to="/login" className="nav-link">
          Se connecter
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/register" className="nav-link">
          Creer un compte
        </Link>
      </li>
    </ul> */}
    </Fragment>
  );

  const sideBar = () => {
    return (
      <Fragment>
        <nav
          id="sidebarMenu"
          class="col-md-3 col-lg-2 p-0 d-md-block sidebar collapse position-fixed"
          style={{
            minHeight: "100vh",
            backgroundColor: "#3c4b64",
            color: "#fff",
          }}
        >
          <div class="position-sticky">
            <ul class="nav flex-column">
              <li class="nav-item p-2">
                <Link
                  className="nav-link "
                  aria-current="page"
                  to="/dashboard/saisieRecette"
                  style={{ color: "#fff" }}
                >
                  <FontAwesomeIcon icon={faEdit} /> Saisies
                </Link>
              </li>
              <li class="nav-item p-2">
                <Link
                  className="nav-link"
                  to="/dashboard/recettes"
                  style={{ color: "#fff" }}
                >
                  <FontAwesomeIcon icon={faNewspaper} /> Recettes Jour
                </Link>
              </li>
              <li class="nav-item p-2">
                <Link
                  className="nav-link"
                  to="/dashboard/recettesMois"
                  style={{ color: "#fff" }}
                >
                  <FontAwesomeIcon icon={faClipboardCheck} /> Recettes Mois
                </Link>
              </li>
              <li class="nav-item p-2">
                <Link
                  className="nav-link"
                  to="/dashboard/parametrage"
                  style={{ color: "#fff" }}
                >
                  <FontAwesomeIcon icon={faCog} /> Parametrage
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </Fragment>
    );
  };

  const userLink = () => {
    return (
      <Fragment>
        <ul class="navbar-nav px-3">
          <li class="nav-item text-nowrap topLink">
            <a
              className="nav-link"
              style={{ color: "#fff" }}
              href="#"
              onClick={logOut}
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> DECONNEXION
            </a>
          </li>
        </ul>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
          GNI
        </a>
        <button
          class="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        {localStorage.usertoken ? userLink() : loginRegLink()}
      </header>
      <div class="container-fluid">
        <div class="row">
          {localStorage.usertoken ? sideBar() : null}

          <main className="col-md-9 offset-md-2 ms-sm-auto col-lg-10 px-md-4 overflow-scroll">
            {/* <div className="container"> */}
            <Alerts />

            <Switch>
              {localStorage.getItem("usertoken") === null ? (
                <Fragment>
                  <Route path="/register" component={Register} />
                  <Route path="/login" component={Login} />
                  <Redirect to="/login" from="/" />
                </Fragment>
              ) : (
                <div> </div>
              )}
            </Switch>
            <Routes />
            {/* </div> */}
          </main>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Landing);
