import React, { Fragment } from 'react';
import RecetteJour from '../recettes/RecetteJour';
import EtatPartenaire from '../Etats/EtatPartenaire';

const RecettesPage = ({recettes}) => {
    return (
        <Fragment>
            <RecetteJour recettes={recettes}/>
        </Fragment>
    );
};

export default RecettesPage;