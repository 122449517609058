import React, { Fragment } from "react";
import RecetteForm from "../recettes/RecetteForm";
import Recettes from "../recettes/Recettes";

const HomePage = (props) => {
  let { recettes } = props;
  /* useEffect(() => {
       console.log(`-- ${props.recettes}`)
    }, []) */
  return (
    <Fragment>
      <div className="card mt-3">
        {recettes != null ? (
          <div >
            <RecetteForm />
            <Recettes recettes={recettes} />
          </div>
        ) : (
          <div style={{ minHeight: "50vh"}} className="d-flex flex-row align-items-center  justify-content-center flex-wrap">
            <div className=" spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default HomePage;
