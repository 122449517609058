import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import RecetteState from "./context/RecetteState";
import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import Navbar from "./pages/Navbar";
import Routes from "./routing/MainRoutes";
import setAuthToken from "./utils/setAuthToken";
import Alerts from "./pages/Alerts";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { Fragment } from "react";

if (localStorage.usertoken) {
  setAuthToken(localStorage.usertoken);
}

const App = () => (
  <AuthState>
    <RecetteState>
      <AlertState>
        <Navbar />
        {/* <div className="container">
          <Alerts />

          <Switch>
            {localStorage.getItem("usertoken") === null ? (
              <Fragment>
                <Route path="/register" component={Register} />
                <Route path="/login" component={Login} />
                <Redirect to="/login" from="/" />
              </Fragment>
            ) : (
              <div> </div>
            )}
          </Switch>
          <Routes />
        </div> */}
      </AlertState>
    </RecetteState>
  </AuthState>
);

export default App;
