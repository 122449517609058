import React, { useContext, useEffect, useState } from "react";
import RecetteItems from "./RecetteItems";
import RecetteContext from "../context/RecetteContext";
import { Row, Col, Badge } from "react-bootstrap";
import JsfPdfDoc from "../Etats/JsPdfDoc";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../table.scss";
import moment from "moment";
import OptionsTable from "./optionsTable";

const Recettes = (props) => {
  const { recettes } = props;
  //const recetteContext = useContext(RecetteContext);
  //const { allRecettes, recettes } = recetteContext;
  let [recetteFilter, setRecetteFilter] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    changeFilter("");
  }, []);

  // Change filter of recette
  const changeFilter = (param) => {
    switch (param) {
      case "tout":
        setRecetteFilter(recettes);
        break;

      default:
        setRecetteFilter(
          recettes.filter((recette) =>
            moment(recette.dateComptable).isAfter(
              moment(new Date()).subtract(30, "days")
            )
          )
        );
        break;
    }
  };

  const handleChange = (param) => {
    setValue(param);
    changeFilter(param);
  };

  return (
    <div class="table-responsive-lg" style={{ marginTop: "10px" }}>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
        <div> </div>
        <div class="btn-toolbar mb-2 mb-md-0 bg-light p-2 rounded">
          <div class="btn-group me-2">
            <button
              type="button"
              onClick={() => handleChange("")}
              class="btn btn-sm btn-outline-secondary"
            >
              30 derniers jours
            </button>
            <button
              type="button"
              onClick={() => handleChange("tout")}
              class="btn btn-sm btn-outline-secondary"
            >
              Tout
            </button>
            {/*    <JsfPdfDoc source="vu-ensemble" title="SUIVI DES RECETTES" data={recetteFilter}/>
            {' '}
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn btn-sm btn-outline-primary download-table-xls-button"
              table="vu-ensemble"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Excel"
            /> */}
            <OptionsTable
              pdfTitle="SUIVI DES RECETTES DU GUICHET DE L'INVESTISSEMENT"
              datas={recetteFilter}
            />
          </div>
        </div>
      </div>
      <div
        class="table-wrapper-scroll-y my-custom-scrollbar"
        style={{ overflowX: "auto" }}
      >
        <table
          id="vu-ensemble"
          className="table table-hover table-striped table-sm table-light"
        >
          <thead className="thead-light">
            <tr>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                #
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Date
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                NºQA
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                NºOR
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Formule Express
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Location et autres
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Frais ANPI
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Droits GNI
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Frais ANPI(60%)
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Frais ANPI total
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Droits
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Pénalités
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Timbres
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Total DGI
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Frais CLIK AFRIC
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Greffe
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Ogapi
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Frais Journal Officiel
              </th>
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                Total
              </th>
              {/* <th scope="col">Operations</th> */}
            </tr>
          </thead>
          <tbody>
            {recetteFilter &&
              recetteFilter.map((recette, i) => (
                <RecetteItems recette={recette} numLigne={i + 1} key={i} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const someStyle = {
  color: "white",
  border: "none",
  background: "none",
};

export default Recettes;
