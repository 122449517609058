import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../context/auth/AuthContext";
import AlertContext from "../context/alert/AlertContext";
import { Link } from 'react-router-dom'
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
const Login = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { login, isAuthenticated, error } = authContext;
  const { setAlert } = alertContext;
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  //const [errors, setErrors] = useState({})

  const { email, password } = user;

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (email !== "" || password !== "") {
      login(user).then(d => {
        props.history.push(`/dashboard/saisieRecette`)
        
      });
      //setAlert(`${error}`, "danger")
      /* .then(res => {
        if (res) {
          
          props.history.push(`/profile`)
        }
      }) */
    } else {
      setAlert("vos champs sont vides...", "danger");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setAlert("Connection effectuée", "success");
      props.history.push("/dashboard");
    }
  }, [isAuthenticated, props.history]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-5 mt-5 p-3 mx-auto rounded" style={loginStyle}>
          <form noValidate onSubmit={onSubmit}>
            <h1 className="text-center h3 mb-3 font-weight-normal">GNI</h1>
            <div className="form-group  mt-4">
              <label htmlFor="email" style={{ color: "black" }}>
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Entrer votre email"
                value={email}
                required
                onChange={onChange}
              />
            </div>
            <div className="form-group mt-4">
              <label htmlFor="password" style={{ color: "black" }}>
                Mot de passe
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Entrer votre mot de passe"
                value={password}
                required
                onChange={onChange}
              />
            </div>
            <div className="mt-4">
              <button type="submit" className="btn btn-primary btn-sm ">
                Se connecter
              </button>
            </div>
          </form>
          {/* <div className="pt-4">
            <Link to="/register" style={{ color: "grey" }} className="nav-link">
              Creer un compte
            </Link>
          </div> */}
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Login;

const loginStyle = {
  color: "black",
  backgroundColor: "white",
  padding: "10px",
  fontFamily: "Arial",
};
