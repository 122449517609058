import React, { useEffect } from 'react';

import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {

    useEffect(() => {
        console.log(`valu : ${localStorage.usertoken}`)
    }, [])

    return (
        <Route { ...rest } render={props => localStorage.getItem('usertoken') === null ? (
            <Redirect to="/login" />
        ) : (
             <Component { ...props }/>
        )}/>
    );
};

export default PrivateRoute;