import React, { useState, Fragment } from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import formatMontant from "../utils/formatMontant";
const ShowTable = (props) => {
  const { choix, filterRecettes, listMontant } = props;

  const [header, setHeader] = useState({
    journal: ["Insertion Journal Officiel"],
    ogapi: ["Frais Ogapi"],
    greffe: ["Frais Greffe"],
    dgi: ["Droits", "Penalites", "Timbres", "Total DGI"],
    droits: ["Droits"],
    penalites: ["Penalites"],
    timbres: ["Timbres"],
    fraisClickAfric: ["CLIK AFRIC"],
    anpi: [
      "Formule Express",
      "Location et autre",
      "Frais ANPI",
      "Droits GNI",
      "Frais ANPI(60%)",
      "Frais ANPI total",
    ],
    allWithoutAnpiPartenaire: [
      "Total DGI",
      "CLIK AFRIC",
      "Frais Greffe",
      "Frais Ogapi",
      "Insertion Journal Officiel",
      "Total Partenaires",
    ],
    allWithoutAnpi: [
      "Total DGI",
      "CLIK AFRIC",
      "Frais Greffe",
      "Frais Ogapi",
      "Insertion Journal Officiel",
      "Total Partenaires",
    ],
    restantPartenaire: [
      "Versement",
      "Quote-part ANPI",
      "Total partenaires",
      "Restant partenaires",
    ],
    etat: ["Suivi individualisé Etat"],
    allFinance: [
      "DENOMINATION SOCIALE",
      "N°ORDRE",
      "FRAIS (FCFA)",
      "ANPI",
      "DGI",
      "GREFFE",
      "OGAPI",
      " J.O",
    ],
    all: [
      "Formule Express",
      "Location et autre",
      "Frais ANPI",
      "Droits GNI",
      "Frais ANPI(60%)",
      "Frais ANPI total",
      "Droits (Code 38)",
      "Pénalités (Code 81)",
      "Timbres (Code 75)",
      "Total DGI",
      "Frais CLIK AFRIC",
      "Frais Greffe",
      "Frais OGAPI",
      "Frais Journal officiel",
      "Total",
    ],
  });

  const headerFun = (choix) => {
    switch (choix) {
      case "journal":
        return header.journal;
        break;
      case "ogapi":
        return header.ogapi;
        break;
      case "greffe":
        return header.greffe;
        break;
      case "click_afric":
        return header.fraisClickAfric;
          break;
      case "dgi":
        return header.dgi;
        break;
      case "droits":
        return header.droits;
        break;
      case "penalites":
        return header.penalites;
        break;
      case "timbres":
        return header.timbres;
        break;
      case "anpi":
        return header.anpi;
        break;
      case "tout-sans-anpi":
        return header.allWithoutAnpi;
        break;
      case "tout-sans-anpi-partenaire":
        return header.allWithoutAnpiPartenaire;
        break;
      case "restant-partenaire":
        return header.restantPartenaire;
        break;
      case "etat":
        return header.etat;
        break;
      case "allFinance":
        return header.allFinance;
        break;
      default:
        return header.all;
        break;
    }
  };

  /*  let total = 0;
  const recetteMoisJour = (table) => {
    if (table != null) {
      total = 0;
      for (let index = 0; index < table.length; index++) {
        total += table[index];
      }
      return total;
    } else {
      return 0;
    }
  }; */

  const totalMoisRender = (montant) => {
    switch (choix) {
      case "anpi":
        return (
          <Fragment>
            <td>{formatMontant(montant.formExpress)}</td>
            <td>{formatMontant(montant.locationAutre)}</td>
            <td>{formatMontant(montant.fraisAnpi)}</td>
            <td>{formatMontant(montant.droitGni)}</td>
            <td>{formatMontant(montant.fraisAnpiPart)}</td>
            <td>{formatMontant(montant.fraisAnpiTotal)}</td>
          </Fragment>
        );

        break;
      case "journal":
        return (
          <Fragment>
            <td>{formatMontant(montant.insertionJournal)}</td>
          </Fragment>
        );
        break;
      case "ogapi":
        return (
          <Fragment>
            <td>{formatMontant(montant.ogapi)}</td>
          </Fragment>
        );
        break;
      case "greffe":
        return (
          <Fragment>
            <td>{formatMontant(montant.greffe)}</td>
          </Fragment>
        );
        break;
        case "click_afric":
        return (
          <Fragment>
            <td>{formatMontant(montant.fraisClickAfric)}</td>
          </Fragment>
        );
        break;
      case "dgi":
        return (
          <Fragment>
            <td>{formatMontant(montant.droits)}</td>
            <td>{formatMontant(montant.penalite)}</td>
            <td>{formatMontant(montant.timbres)}</td>
            <td>{formatMontant(montant.total)}</td>
          </Fragment>
        );
        break;
      case "droits":
        return (
          <Fragment>
            <td>{formatMontant(montant.droits)}</td>
          </Fragment>
        );
        break;
      case "penalites":
        return (
          <Fragment>
            <td>{formatMontant(montant.penalite)}</td>
          </Fragment>
        );
        break;
      case "timbres":
        return (
          <Fragment>
            <td>{formatMontant(montant.timbres)}</td>
          </Fragment>
        );
        break;
      case "tout-sans-anpi":
        return (
          <Fragment>
            <td>{formatMontant(montant.total)}</td>
            <td>{formatMontant(montant.fraisClickAfric)}</td>
            <td>{formatMontant(montant.greffe)}</td>
            <td>{formatMontant(montant.ogapi)}</td>
            <td>{formatMontant(montant.insertionJournal)}</td>
            <td>
              {formatMontant(
                montant.total +
                montant.fraisClickAfric +
                  montant.greffe +
                  montant.ogapi +
                  montant.insertionJournal
              )}
            </td>
          </Fragment>
        );
        break;
        case "tout-sans-anpi-partenaire":
          return (
            <Fragment>
              <td>{formatMontant(montant.total)}</td>
              <td>{formatMontant(montant.clikAfric)}</td>
              <td>{formatMontant(montant.greffe)}</td>
              <td>{formatMontant(montant.ogapi)}</td>
              <td>{formatMontant(montant.journalOfficiel)}</td>
              <td>
                {formatMontant(
                  montant.total +
                  montant.clikAfric +
                    montant.greffe +
                    montant.ogapi +
                    montant.journalOfficiel
                )}
              </td>
            </Fragment>
          );
          break;
      case "etat":
        return (
          <Fragment>
            <td> {formatMontant(montant.dfde * 0.25)}</td>
          </Fragment>
        );
        break;
      default:
        return (
          <Fragment>
            <td>{formatMontant(montant.formExpress)}</td>
            <td>{formatMontant(montant.locationAutre)}</td>
            <td>{formatMontant(montant.fraisAnpi)}</td>
            <td>{formatMontant(montant.droitGni)}</td>
            <td>{formatMontant(montant.fraisAnpiPart)}</td>
            <td>{formatMontant(montant.fraisAnpiTotal)}</td>
            <td>{formatMontant(montant.droits)}</td>
            <td>{formatMontant(montant.penalite)}</td>
            <td>{formatMontant(montant.timbres)}</td>
            <td>{formatMontant(montant.total)}</td>
            <td>{formatMontant(montant.fraisClickAfric)}</td>
            <td>{formatMontant(montant.greffe)}</td>
            <td>{formatMontant(montant.ogapi)}</td>
            <td>{formatMontant(montant.insertionJournal)}</td>
            <td>{formatMontant(montant.totalFinal)}</td>
          </Fragment>
        );
        break;
    }
  };

  const bodyFun = (ligne) => {
    switch (choix) {
      case "journal":
        return (
          <Fragment>
            <td>{formatMontant(ligne.insertionJournal)}</td>
          </Fragment>
        );
        break;
      case "ogapi":
        return (
          <Fragment>
            <td>{formatMontant(ligne.ogapi)}</td>
          </Fragment>
        );
        break;
        case "click_afric":
          return (
            <Fragment>
              <td>{formatMontant(ligne.fraisClickAfric)}</td>
            </Fragment>
          );
          break;
        
      case "greffe":
        return (
          <Fragment>
            <td>{formatMontant(ligne.greffe)}</td>
          </Fragment>
        );
        break;
      case "dgi":
        return (
          <Fragment>
            <td>{formatMontant(ligne.droits)}</td>
            <td>{formatMontant(ligne.penalite)}</td>
            <td>{formatMontant(ligne.timbres)}</td>
            <td>{formatMontant(ligne.total)}</td>
          </Fragment>
        );
        break;
      case "droits":
        return (
          <Fragment>
            <td>{formatMontant(ligne.droits)}</td>
          </Fragment>
        );
        break;
      case "penalites":
        return (
          <Fragment>
            <td>{formatMontant(ligne.penalite)}</td>
          </Fragment>
        );
        break;
      case "timbres":
        return (
          <Fragment>
            <td>{formatMontant(ligne.timbres)}</td>
          </Fragment>
        );
      case "anpi":
        return (
          <Fragment>
            <td>{formatMontant(ligne.formExpress)}</td>
            <td>{formatMontant(ligne.locationAutre)}</td>
            <td>{formatMontant(ligne.fraisAnpi)}</td>
            <td>{formatMontant(ligne.droitGni)}</td>
            <td>{formatMontant(ligne.fraisAnpiPart)}</td>
            <td>{formatMontant(ligne.fraisAnpiTotal)}</td>
          </Fragment>
        );
        break;
      case "tout-sans-anpi":
        return (
          <Fragment>
            <td>{formatMontant(ligne.total)}</td>
            <td>{formatMontant(ligne.fraisClickAfric)}</td>
            <td>{formatMontant(ligne.greffe)}</td>
            <td>{formatMontant(ligne.ogapi)}</td>
            <td>{formatMontant(ligne.insertionJournal)}</td>
            <td>
              {formatMontant(
                ligne.total +
                ligne.fraisClickAfric +
                  ligne.greffe +
                  ligne.ogapi +
                  ligne.insertionJournal
              )}
            </td>
          </Fragment>
        );
      case "tout-sans-anpi-partenaire":
        return (
          <Fragment>
            <td>{formatMontant(ligne.total)}</td>
            <td>{formatMontant(ligne.clikAfric)}</td>
            <td>{formatMontant(ligne.greffe)}</td>
            <td>{formatMontant(ligne.ogapi)}</td>
            <td>{formatMontant(ligne.journalOfficiel)}</td>
            <td>
              {formatMontant(
                ligne.total +
                ligne.clikAfric +
                  ligne.greffe +
                  ligne.ogapi +
                  ligne.journalOfficiel
              )}
            </td>
          </Fragment>
        );
      case "restant-partenaire":
        return <Fragment></Fragment>;
      case "Suivi individualisé Etat":
        return (
          <Fragment>
            <td>{formatMontant(ligne.dfde)}</td>
          </Fragment>
        );
        break;
      case "allFinance":
        return (
          <Fragment>
            <td>{formatMontant(ligne.denomination)}</td>
            <td>{formatMontant(ligne.ordre)}</td>
            <td>{formatMontant(ligne.frais)}</td>
            <td>{formatMontant(ligne.anpi)}</td>
            <td>{formatMontant(ligne.dgi)}</td>
            <td>{formatMontant(ligne.greffe)}</td>
            <td>{formatMontant(ligne.ogapi)}</td>
            <td>{formatMontant(ligne.insertionJournal)}</td>
          </Fragment>
        );
        break;
      default:
        return (
          <Fragment>
            <td>{formatMontant(ligne.formExpress)}</td>
            <td>{formatMontant(ligne.locationAutre)}</td>
            <td>{formatMontant(ligne.fraisAnpi)}</td>
            <td>{formatMontant(ligne.droitGni)}</td>
            <td>{formatMontant(ligne.fraisAnpiPart)}</td>
            <td>{formatMontant(ligne.fraisAnpiTotal)}</td>
            <td>{formatMontant(ligne.droits)}</td>
            <td>{formatMontant(ligne.penalite)}</td>
            <td>{formatMontant(ligne.timbres)}</td>
            <td>{formatMontant(ligne.total)}</td>
            <td>{formatMontant(ligne.fraisClickAfric)}</td>
            <td>{formatMontant(ligne.greffe)}</td>
            <td>{formatMontant(ligne.ogapi)}</td>
            <td>{formatMontant(ligne.insertionJournal)}</td>
            <td>{formatMontant(ligne.totalFinal)}</td>
          </Fragment>
        );
        break;
    }
  };

  return (
    <div >
      <Table responsive id="table-to-xls" size="sm" variant="light" striped bordered hover >
        <thead className="thead-light">
          <tr>
            <th>#</th>

            {headerFun(choix).map((e) => (
              <th style={{ whiteSpace: "nowrap" }}>{e}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filterRecettes !== null &&
            choix !== "etat" &&
            filterRecettes.map((ligne) => (
              <tr>
                <td>
                  <Moment format="DD/MM/YYYY">{ligne.dateComptable}</Moment>
                </td>
                {bodyFun(ligne)}
              </tr>
            ))}

          <tr class="table-warning font-weight-bold">
           {choix !== "tout-sans-anpi-partenaire" ? <td>Total mois</td> : null} 
            {listMontant &&
              listMontant.map((montant) => totalMoisRender(montant))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ShowTable;
