import React, { Fragment } from "react";
import JsfPdfDoc from "../Etats/JsPdfDoc";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const OptionsTable = ({ choix, pdfTitle, datas, listMontant }) => {

  return (
    <Fragment>
      <JsfPdfDoc
        source="vu-ensemble"
        title={pdfTitle}
        data={datas}
        listMontant={listMontant}
        header={choix}
      />{" "}
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="btn btn-sm btn-outline-primary download-table-xls-button"
        table="table-to-xls"
        filename="tablexls"
        sheet="tablexls"
        buttonText="Excel"
      />
    </Fragment>
  );
};

export default OptionsTable;
